import {
    AXPSummaryData,
    AXPSummaryBySiteData,
    AXPSummaryBySite,
    Customer,
    CustomSiteName,
    Dashlet,
    Equipment,
    Location,
    Point,
    GraphData,
    StreamGraph
} from '@models/index';
import { Subject } from 'rxjs';

export interface DashletAXPCloudHealthSummary {
    id: string;
    title: string;
    description: string;
    status: 'healthy' | 'warning' | 'critical';
    lastUpdated: Date;
    metrics: CloudHealthMetric[];
}

export interface CloudHealthMetric {
    name: string;
    value: number;
    unit: string;
}

interface AXPEquipment {
    label: string;
    value: string;
}

export class DashletAXPCloudHealthSummaryModel extends Dashlet {
    public customer: Customer;
    public service: Location;
    public equipment: AXPEquipment[] = [];
    public pointArray: Point[] = null;
    public chartDataUpdated: Subject<AXPSummaryBySite> = new Subject();
    public settingsChanges: Subject<null> = new Subject();
    public hourSetting: number;
    public summaryPeriod = { value: 0 };
    public lastUpdatedDataAt: Date = new Date(0);
    public nameTag: string;
    public sizeChange: Subject<number> = new Subject<number>();
    public customSiteNames: CustomSiteName[] = [];
    public loadingDocuments: boolean = true;
    private axpSessionsData: AXPSummaryBySite = { All: [] };
    public axpSessionGraphData: { [key: string]: GraphData[] } = { All: [] };
    public showPSTNCallsOnly: boolean = false;

    public readonly commandTypeIdAXPCloudHealthSummary = '6AF35A51-11FB-4592-8373-585318AFC1FB';
    public readonly commandTypeIdAXPCloudPSTNHealthSummary = '';
    constructor() {
        super();
        this.sizes = [
            {
                id: 0,
                label: 'Small',
                cols: 10,
                rows: 11
            },
            {
                id: 1,
                label: 'Medium',
                cols: 13,
                rows: 11
            }
        ];
        this.applySize(0);
        this.resetData();
        this.summaryPeriod = { value: 0 };
    }

    applySettings(v: { [key: string]: any }) {
        const previousSettings = {
            customer: this.customer ? this.customer.customerId : '',
            service: this.service ? this.service.locationId : '',
            equipment: this.equipment ? this.equipment : [],
            summaryPeriod: this.summaryPeriod,
            showPSTNCallsOnly: this.showPSTNCallsOnly,
            customSiteNames: this.customSiteNames
        };

        super.applySettings(v);
        this.configured = v.customer && v.service && v.equipment && v.summaryPeriod;

        this.hourSetting = v.summaryPeriod ? Number(v.summaryPeriod.value) : null;
        this.summaryPeriod = v.summaryPeriod;
        this.showPSTNCallsOnly = v.showPSTNCallsOnly;
        if (v.customer) {
            this.customer = new Customer(v.customer.value, v.customer.label);
        } else {
            this.customer = new Customer('', '');
        }
        if (this.configured) {
            this.service = new Location(v.service.value, v.service.label);
        }

        this.service = v.service ? new Location(v.service.value, v.service.label) : new Location('', '');

        this.nameTag = this.configured ? `${v.service.label}` : 'Unconfigured';

        this.customNameTag = v.nameTag;

        if (v.equipment?.length) {
            this.equipment = v.equipment.filter(names => names.label !== 'Unknown');
        }
        if (v.customSiteNames) {
            this.customSiteNames = v.customSiteNames.filter(names => names.label !== 'Unknown');
        }

        this.updateSize();

        const currentSettings = {
            customer: this.customer.customerId,
            service: this.service.locationId,
            equipment: this.equipment,
            summaryPeriod: this.summaryPeriod,
            showPSTNCallsOnly: this.showPSTNCallsOnly,
            customSiteNames: this.customSiteNames
        };

        if (
            this.areSettingsDifferent(previousSettings, currentSettings) &&
            this.areSettingsValid(currentSettings) &&
            this.configured
        ) {
            this.settingsChanges.next(null);
        }
    }

    private areSettingsDifferent(prev: any, curr: any): boolean {
        return JSON.stringify(prev) !== JSON.stringify(curr);
    }

    private areSettingsValid(settings: any): boolean {
        return this.customer && this.service.locationId && this.equipment?.length && settings.summaryPeriod;
    }

    applySize(id: number) {
        super.applySize(id);
        this.sizeChange.next(id);
        this.updateSize();
        this.refreshNameTag();
    }

    refreshNameTag() {
        if (!this.lastUpdatedDataAt && this.lastUpdatedDataAt.getTime() === 0) {
            return;
        }
        const originalDate = new Date(this.lastUpdatedDataAt),
            originalDateNoMinutes = new Date(this.lastUpdatedDataAt);
        originalDateNoMinutes.setMinutes(0, 0, 0);

        const time = originalDateNoMinutes
            .toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
            .toUpperCase();

        const date = new Date(this.lastUpdatedDataAt);
        const month = originalDate.toLocaleDateString(undefined, { month: 'short' });
        const day = date.getDate().toString().padStart(2, '0');
        const timeString = this.extractTime(date);

        const firstDateVal: Date = new Date(originalDateNoMinutes);
        firstDateVal.setHours(firstDateVal.getHours() - +this.summaryPeriod?.value);
        const firstTime = firstDateVal
            .toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
            .toUpperCase();

        let period = '';
        period = firstTime + ' - ' + time;

        if (this.service !== undefined && this.equipment !== undefined && this.summaryPeriod !== undefined) {
            this.generatedNameTag = `${this.nameTag} | ${period} | Last updated at ${day}-${month} ${timeString}`;
        }
    }

    private extractTime(date: Date): string {
        const hours24 = date.getHours(); // Use local time
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const isPM = hours24 >= 12;
        const hours12 = hours24 % 12 || 12; // Convert 24-hour format to 12-hour format
        const period = isPM ? 'PM' : 'AM';
        const timeString = `${hours12}:${minutes} ${period}`;
        return timeString;
    }

    private updateSize() {
        let h = 0;
        let w = 0;
        this.applySizeExpansion(w, h);
    }

    public refreshChartData() {
        this.chartDataUpdated.next(this.axpSessionsData);
        this.refreshNameTag();
    }

    private initNodeData(): StreamGraph {
        return {
            data: {
                streams: 0,
                timeStamp: null
            }
        } as StreamGraph;
    }
    public processAxpSessionData(axpSessionSummary: string, documentTimestamp: Date, pointArray: Point[]): void {
        if (axpSessionSummary) this.loadingDocuments = false;
        const adjustedTimestamp = new Date(documentTimestamp);
        const parsedAxpSiteData: AXPSummaryBySiteData = JSON.parse(axpSessionSummary);
        if (!parsedAxpSiteData['All']) return;

        const pointExists = pointArray.some(point => point.x.toISOString() === adjustedTimestamp.toISOString());
        if (!pointExists) {
            let oldestPointIndex = 0;
            let oldestPointTime = this.pointArray[0]?.x.getTime();
            for (let i = 1; i < this.pointArray.length; i++) {
                if (this.pointArray[i].x.getTime() < oldestPointTime) {
                    oldestPointTime = pointArray[i].x.getTime();
                    oldestPointIndex = i;
                }
            }
            if (pointArray.length >= this.hourSetting * 4) {
                pointArray.splice(oldestPointIndex, 1);
            }
            pointArray.push({ x: adjustedTimestamp, y: 0 });
        }

        Object.keys(parsedAxpSiteData).forEach(siteName => {
            const siteRecords = this.axpSessionsData[siteName] || [];
            const previousRecord = siteRecords[siteRecords.length - 1];

            if (previousRecord?.DocumentCreation.getTime() === adjustedTimestamp.getTime()) return;

            const siteData = { ...parsedAxpSiteData[siteName], DocumentCreation: adjustedTimestamp };
            siteRecords.push(siteData);

            if (this.hourSetting && siteRecords.length > pointArray.length) {
                siteRecords.shift();
            }
            this.axpSessionsData[siteName] = siteRecords;
            this.buildGraphData(siteRecords, siteName, pointArray);
        });
        pointArray.sort((a: Point, b: Point) =>
            a.x.getTime() < b.x.getTime() ? 1 : b.x.getTime() < a.x.getTime() ? -1 : 0
        );
    }

    private buildGraphData(axpRecordsData: AXPSummaryData[], siteName: string, pointArray: Point[]): void {
        this.axpSessionGraphData[siteName] = pointArray.map(point => {
            const index = axpRecordsData.findIndex(
                (item: AXPSummaryData) => new Date(item.DocumentCreation).toISOString() === point.x.toISOString()
            );
            const goodStream = this.initNodeData();
            const satisfactoryStream = this.initNodeData();
            const poorStream = this.initNodeData();
            if (index !== -1) {
                const timeStamp = axpRecordsData[index].DocumentCreation;
                this.equipment.forEach(element => {
                    Object.keys(axpRecordsData[index])
                        .filter(x => x !== 'DocumentCreation')
                        .forEach(equipmentId => {
                            const record = axpRecordsData[index][element.value];
                            goodStream.data.streams += record.StreamQuality.Good;
                            satisfactoryStream.data.streams += record.StreamQuality.Satisfactory;
                            poorStream.data.streams += record.StreamQuality.Bad;
                        });
                });
                goodStream.data.timeStamp = timeStamp;
                satisfactoryStream.data.timeStamp = timeStamp;
                poorStream.data.timeStamp = timeStamp;
            } else {
                goodStream.data.streams = 0;
                satisfactoryStream.data.streams = 0;
                poorStream.data.streams = 0;

                const timeStamp = point.x;
                goodStream.data.timeStamp = timeStamp;
                satisfactoryStream.data.timeStamp = timeStamp;
                poorStream.data.timeStamp = timeStamp;
            }

            return {
                Good: goodStream,
                Satisfactory: satisfactoryStream,
                Poor: poorStream
            };
        });
    }

    public resetData(): void {
        this.axpSessionsData = { All: [] };
        this.axpSessionGraphData = { All: [] };
        this.pointArray = [];
    }

    public dispose() {
        this.settingsChanges.complete();
    }
}
