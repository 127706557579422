import {
    DashletSettingsForm,
    DashletSettingsGroup,
    DashletSettingDropdown,
    DashletSettingDropdownGrouped,
    SelectItem,
    DashletSettingList,
    DashletSettingText
} from '@models/index';
import { AccountService, DashletSettingsService } from '@services/index';

export class DashletSettingsFormMSTeamsCallSummary extends DashletSettingsForm {
    private customer: DashletSettingDropdown;
    private location: DashletSettingDropdown;
    public period: DashletSettingDropdown;
    private equipment: DashletSettingDropdown;
    private customSiteNames: DashletSettingList<string>;

    private isCust: boolean;
    private customerId: string;

    constructor(
        private readonly settingsService: DashletSettingsService,
        private readonly accountService: AccountService
    ) {
        super();
        this.init();
        this.loadCustomers();
    }

    loadState(obj: { [key: string]: object }): void {
        super.loadState(obj);

        if (this.customer.value) this.loadLocations(this.customer.value);

        if (this.location.value) {
            this.loadequipments(this.location.value);
            this.loadCustomSiteNames(this.equipment.value);
        }
    }

    private init(): void {
        const group = new DashletSettingsGroup();
        const s = (group.settings = []);
        const period = (this.period = new DashletSettingDropdown());

        period.label = 'Summary Period';
        period.stateKey = 'summaryPeriod';
        period.items = [];

        const daily = new SelectItem();
        daily.label = '24 hours';
        daily.value = '24';
        period.items.push(daily);

        const twelveHourly = new SelectItem();
        twelveHourly.label = '12 hours';
        twelveHourly.value = '12';

        period.items.push(twelveHourly);

        const sixHourly = new SelectItem();
        sixHourly.label = '6 hours';
        sixHourly.value = '6';
        period.items.push(sixHourly);

        const hourly = new SelectItem();
        hourly.label = '1 hour';
        hourly.value = '1';
        period.items.push(hourly);
        s.push(period);

        const user = this.accountService.getUserDetails();
        this.isCust = user.EntityGroup === 2;
        this.customerId = user.EntityId;

        this.customer = new DashletSettingDropdown();
        this.customer.label = 'Customer';
        this.customer.stateKey = 'customer';
        this.customer.disabled = true;
        this.customer.change.subscribe(() => {
            if (!this.location.value) this.clearAndDisable(this.location);
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.customSiteNames.values) this.clearAndDisable(this.customSiteNames);
            this.loadLocations(this.customer.value);
        });
        s.push(this.customer);

        this.location = new DashletSettingDropdown();
        this.location.label = 'Location';
        this.location.stateKey = 'location';
        this.location.disabled = true;
        this.location.change.subscribe(() => {
            if (!this.equipment.value) this.clearAndDisable(this.equipment);
            if (!this.customSiteNames.values) this.clearAndDisable(this.customSiteNames);
            this.loadequipments(this.location.value);
        });
        s.push(this.location);

        this.equipment = new DashletSettingDropdown();
        this.equipment.label = 'Service';
        this.equipment.stateKey = 'equipment';
        this.equipment.disabled = true;
        this.equipment.change.subscribe(() => {
            if (!this.customSiteNames.values) this.clearAndDisable(this.customSiteNames);
            this.loadCustomSiteNames(this.equipment.value);
        });
        s.push(this.equipment);

        this.customSiteNames = new DashletSettingList();
        this.customSiteNames.label = 'Custom Site Name';
        this.customSiteNames.stateKey = 'customSiteNames';
        this.customSiteNames.disabled = true;
        s.push(this.customSiteNames);

        const customName = new DashletSettingText(40);
        customName.label = 'Custom Dashlet Name';
        customName.stateKey = 'customName';
        s.push(customName);

        this.updateSettings(group);
    }

    private loadCustomers(): void {
        this.enableAndLoading(this.customer);

        this.settingsService.getUserAssociatedCustomers().subscribe(
            customers => {
                this.customer.items = customers;
                this.customer.loadingComplete();
            },
            () => {
                this.customer.loadingComplete();
                this.customer.disabled = true;
                //show error notification here
            }
        );
    }

    private loadLocations(customer: string): void {
        this.enableAndLoading(this.location);

        this.settingsService.getLocations(customer).subscribe(
            locations => {
                this.location.items = locations;
                this.location.loadingComplete();
            },
            () => {
                this.location.loadingComplete();
                this.location.disabled = true;
                //show error notification here
            }
        );
    }

    private loadequipments(locationId: string): void {
        this.enableAndLoading(this.equipment);
        this.settingsService.getTeamsTenants(locationId).subscribe(
            receivers => {
                this.equipment.items = receivers;
                this.equipment.loadingComplete();
            },
            () => {
                this.equipment.loadingComplete();
                this.equipment.disabled = true;
                //show error notification here
            }
        );
    }

    private loadCustomSiteNames(equipmentId: string): void {
        this.enableAndLoading(this.customSiteNames);
        this.settingsService.getTeamsSites(equipmentId).subscribe({
            next: (customSiteNames: SelectItem[]) => {
                this.customSiteNames.items = customSiteNames;
                this.customSiteNames.loadingComplete();
            },
            error: () => {
                this.customSiteNames.loadingComplete();
                this.customSiteNames.disabled = true;
            }
        });
    }

    private clearAndDisable(
        widget: DashletSettingDropdownGrouped | DashletSettingDropdown | DashletSettingList<string>
    ) {
        widget.disabled = true;
        widget.items = [];
        if (widget instanceof DashletSettingList) widget.values = [];
        else widget.value = null;
    }

    private enableAndLoading(
        widget: DashletSettingDropdownGrouped | DashletSettingDropdown | DashletSettingList<string>
    ) {
        widget.disabled = false;
        widget.loadingBegin();
    }
}
