/* eslint-disable no-undef */
import * as Actions from '@actions/index';
import {
    AccountService,
    DashletDataProcessService,
    DashletService,
    NotificationService,
    TimeService
} from '@services/index';
import {
    alarmStatus,
    AlarmAdditionalData,
    AlarmExtended,
    AlarmResponseLogData,
    ColumnTypes,
    UserDetails,
    MenuItem,
    Filters,
    SelectItem,
    CustomerModel
} from '@models/index';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ContextMenu } from 'primeng/contextmenu';
import { DashletAlarmsSummary } from '@models/index';
import { Observable, of, Subject, Subscription, forkJoin } from 'rxjs';
import { retry, map, tap, catchError, take } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { AppState, selectEntity } from '@reducers/index';

import { CustomerLogo, Customer } from '@models/index';
import { interval } from 'rxjs/internal/observable/interval';
import { startWith, switchMap, share } from 'rxjs/operators';
import { SortDirection } from '@angular/material/sort';
import { environment } from '@environments/environment';
import { MatDialog } from '@angular/material/dialog';
import {
    AlarmSuppressionDialog,
    SuppressAlarmDto
} from './alarm-suppression-dialog/alarm-suppression-dialog.component';
import * as CustomersActions from '@actions/customers/customers.actions';

enum detailAlarmStatus {
    resolving = -1,
    resolveFailed,
    resolveSucceed
}
interface Selects {
    label: string;
    value: string;
}

interface AlarmRepeats {
    repeats: number;
    date: Date;
}

class Alarm {
    id!: string;
    displayName!: string;
    administeredId!: string;
    firstOccurred!: Date;
    lastOccurred!: Date | null;
    equipmentId!: string;
    equipment!: string;
    customerId!: string;
    customer!: string;
    severity!: number;
    resolveStatus!: alarmStatus | null;
}

@Component({
    selector: 'app-dashlet-alarms-summary',
    templateUrl: './dashlet-alarms-summary.component.html',
    styleUrls: ['./dashlet-alarms-summary.component.scss']
})
export class DashletAlarmsSummaryComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletAlarmsSummary;
    @Input() showDetailNavigation: boolean = true;
    @Output() showDetailDrilldown = new EventEmitter();
    @Output() disableNavigation = new EventEmitter();
    @ViewChild('rowMenu') rowMenu: ContextMenu;

    public repeatColumns: ColumnTypes[] = [
        {
            columnDef: 'repeats',
            header: 'Repeats',
            cell: (row: AlarmRepeats) => row.repeats
        },
        {
            columnDef: 'date',
            header: 'Date',
            cell: (row: AlarmRepeats) => this.timeService.convertToUserTimeZone(row.date),
            type: 'date'
        }
    ];

    public additionalColumns: ColumnTypes[] = [
        {
            columnDef: 'key',
            header: 'Key',
            cell: (row: AlarmAdditionalData) => row.key
        },
        {
            columnDef: 'value',
            header: 'Value',
            cell: (row: AlarmAdditionalData) => row.value
        }
    ];

    public responseColumns: ColumnTypes[] = [
        {
            columnDef: 'ruleName',
            header: 'Alarm Rule',
            cell: (row: AlarmResponseLogData) => (row.isRuleDeleted ? row.ruleName + '[Removed]' : row.ruleName)
        },
        {
            columnDef: 'workflowDefinitionName',
            header: 'Workflows Applied',
            cell: (row: AlarmResponseLogData) =>
                row.workflowDefinitionName === null || row.workflowDefinitionName === ''
                    ? 'Not Found'
                    : row.isWorkflowDeleted
                    ? row.workflowDefinitionName + '[Removed]'
                    : row.workflowDefinitionName
        },
        {
            columnDef: 'matchState',
            header: 'Matched',
            cell: (row: AlarmResponseLogData) =>
                row.matchState?.toLowerCase() === this.alarmNotMatchedConst ? false : true
        }
    ];

    public alarmResponseMenu: MenuItem[] = [
        {
            label: 'Open Alarm Rules',
            command: (data?: AlarmResponseLogData) => this.openAlarmRulesPage(),
            disabled: false,
            title: 'Open alarm rules page'
        },
        {
            label: 'Open Workflows',
            command: (data?: AlarmResponseLogData) => this.openWorkflowDefinitionPage(),
            disabled: false,
            title: 'Open workflow defiition page'
        }
    ];

    public columns: ColumnTypes[] = [
        {
            columnDef: 'severity',
            header: '',
            cell: (element: Alarm) => element.severity,
            type: 'alarmSeverity',
            dataTooltip: (element: Alarm) => `Severity ${element.severity}`
        },
        {
            columnDef: 'displayName',
            header: 'Display Name',
            cell: (element: Alarm) => `${element.displayName}`,
            dataTooltip: (element: Alarm) => this.getDisplayNameTitle(element),
            filterType: 'text'
        },
        {
            columnDef: 'administeredId',
            header: 'Admin ID',
            cell: (element: Alarm) => `${element.administeredId}`,
            dataTooltip: (element: Alarm) => this.getAdministeredIdTitle(element),
            filterType: 'text'
        },
        {
            columnDef: 'equipment',
            header: 'Equipment',
            cell: (element: Alarm) => `${element.equipment}`,
            dataTooltip: (element: Alarm) => this.getEquipmentTitle(element),
            filterType: 'text'
        },
        {
            columnDef: 'firstOccurred',
            header: 'Activated ',
            cell: (element: Alarm) => this.timeService.convertToUserTimezoneForAlarms(element.firstOccurred),
            filterType: 'text',
            display: () => this.expandedView
        },
        {
            columnDef: 'customer',
            header: 'Customer ',
            cell: (element: Alarm) => `${element.customer}`,
            dataTooltip: (element: Alarm) => this.getCustomerTitle(element),
            filterType: 'select',
            display: () => this.isHuge && this.userDetails.EntityGroup !== 2
        }
    ];

    private selectedCustomers: CustomerModel[];
    public customerAssociatedData: CustomerModel[];

    userDetails: UserDetails;
    selectedAlarmId: number;
    detailAlarmStatus: detailAlarmStatus;

    repeatsLoading: boolean = true;
    additionalLoading: boolean = true;
    responseLoading: boolean = true;

    openSubcontainer: string;
    detailsAlarm: any;
    detailsAlarmExtended: Observable<AlarmExtended> = new Subject();
    detailsAlarmRepeats: Observable<AlarmRepeats[]> = new Subject();
    detailsAlarmAdditional: Observable<AlarmAdditionalData[]> = new Subject();
    detailsAlarmResponseLog: Observable<AlarmResponseLogData[]> = new Subject();
    detailsIndex: number;
    detailsHasPrev: boolean;
    detailsHasNext: boolean;

    severityRange: number[];
    items: MenuItem[];
    customers: SelectItem[] = [];
    equipments: SelectItem[] = [];
    selectedEquipments: Selects[];
    sortType: string;
    sortTypeRules: string;

    filterCount: number = 0;
    filteredCountSubject = new Subject<number>();
    filterCountsubscription: Subscription;
    filteredAlarmSubject = new Subject<any>();
    filterAlarmsubscription: Subscription;

    loading: boolean;
    alarmsLoading: boolean = true;
    public displayTable: boolean = true;

    displayNameSearch: any;
    administeredIdSearch: any;
    equipmentSearch: any;
    activatedSearch: any;
    timeInterval: any;

    public sortField = 'severity';
    public sortDirection: SortDirection = 'asc';

    public filters: Filters = {};

    private readonly alarmNotMatchedConst: string = 'alarmnotmatched';
    private uiSubscription: Subscription = new Subscription();
    private subscription: Subscription = new Subscription();

    constructor(
        private dashletService: DashletService,
        private accountService: AccountService,
        private dashletDataProcessingService: DashletDataProcessService,
        private cdRef: ChangeDetectorRef,
        private store$: Store<AppState>,
        private notificationService: NotificationService,
        private dialog: MatDialog,
        private snackBarService: NotificationService,
        private timeService: TimeService
    ) {
        this.userDetails = this.accountService.getUserDetails();
    }

    public ngOnInit(): void {
        this.uiSubscription.add(
            this.dashlet.settingsChanged.subscribe(() => {
                this.displayTable = false;
                this.filters = {};
                this.subscription.unsubscribe();
                this.subscription = new Subscription();
                this.cdRef.detectChanges();
                this.updateSelectedCustomers();
                this.dashlet.resetData();
                this.subscribeToData();
                this.displayTable = true;
            })
        );

        this.uiSubscription.add(
            (this.filterCountsubscription = this.filteredCountSubject.subscribe(count => {
                this.filterCount = count;
            }))
        );
        this.uiSubscription.add(
            (this.filterAlarmsubscription = this.filteredAlarmSubject.subscribe(alarms => {
                this.dashlet.alarms = alarms;
            }))
        );
        this.uiSubscription.add(
            this.dashlet.sizeChange.subscribe(() => {
                this.dashlet.updateAlarms();
                this.cdRef.detectChanges();
            })
        );
        this.subscribeToData();
    }

    private subscribeToData(): void {
        this.severityRange = [0, 10];
        if (this.dashlet.customers.length === 1 && this.dashlet.customerIds.length === 1) {
            if (this.dashlet.customers[0] !== 'Virsae Owners') {
                this.dashlet.customer = new Customer(this.dashlet.customerIds[0], this.dashlet.customers[0]);
                this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));
                this.subscription.add(
                    this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                        if (logo) {
                            this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                        }
                    })
                );
            }
        }
        if (this.dashlet.configured && this.dashlet.customerIds) {
            this.dashlet.customerIds.forEach(id => {
                const alarmData$ = interval(30 * 1000).pipe(
                    startWith(0),
                    switchMap(() =>
                        this.dashletDataProcessingService.getAlarmsData(id, this.dashlet.commandTypeIdAlarms)
                    ),
                    share()
                );
                const subscription: Subscription = alarmData$.subscribe({
                    next: res => {
                        this.alarmsLoading = false;
                        this.dashlet.processAlarmData(id, res);
                        this.cdRef.detectChanges();
                    },
                    error: error => {
                        this.alarmsLoading = false;
                        this.cdRef.detectChanges();
                    }
                });
                this.subscription.add(subscription);
            });
        }
        let customersData$ = this.store$.select(state => state.customers.userAssociatedCustomers);
        this.subscription.add(
            customersData$.subscribe(result => {
                if (result && result.length) {
                    this.customerAssociatedData = result;
                    this.items = this.buildMenu();
                } else {
                    this.store$.dispatch(CustomersActions.GetUserAssociatedCustomers());
                }
            })
        );
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    public ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    public ngOnDestroy(): void {
        this.uiSubscription.unsubscribe();
        this.subscription.unsubscribe();
    }

    get expandedView() {
        if (this.dashlet) {
            const s = this.dashlet.getSize();
            if (s !== null) return s.id === 2 || s.id === 3;
        }
        return false;
    }

    get isHuge() {
        const s = this.dashlet.getSize();
        if (s !== null) return s.id === 3;
        return false;
    }
    get isSmall() {
        const s = this.dashlet.getSize();
        if (s !== null) return s.id === 0;
        return false;
    }

    get showCustomer() {
        const d = this.userDetails;
        return d.EntityGroup !== 2;
    }

    public tableSize(): number {
        const s = this.dashlet.getSize();
        if (s.id === 0) return 4;
        else if (s.id === 1) return 16;
        else if (s.id === 2) return 16;
        else if (s.id === 3) return 28;
        return 4;
    }

    public getColumnDisplay(): boolean {
        return this.expandedView;
    }

    public getSeverityColor(severity: number): string {
        return this.dashletService.getAlarmSeverityColor(severity);
    }

    public openDetails(alarmId: string) {
        this.openSubcontainer = 'details';
        this.dashlet.displayHeader = false;
        this.detailsIndex = this.dashlet.alarms.map(alarm => alarm.id).indexOf(alarmId);
        this.showDetailDrilldown.emit();
        this.setDetailsAlarm();
    }

    public closeDetails(): void {
        this.openSubcontainer = null;
        this.dashlet.displayHeader = true;
    }

    public detailsPrev(): void {
        if (this.detailsHasPrev) {
            this.detailsIndex--;
            this.setDetailsAlarm();
        }
    }

    public detailsNext(): void {
        if (this.detailsHasNext) {
            this.detailsIndex++;
            this.setDetailsAlarm();
        }
    }

    private setDetailsAlarm(): void {
        this.detailAlarmStatus = null;
        const alarm = (this.detailsAlarm = this.dashlet.alarms[this.detailsIndex]);
        this.detailsHasPrev = this.detailsIndex > 0;
        this.detailsHasNext = this.detailsIndex < this.dashlet.alarms.length - 1;
        //query extra data
        this.detailsAlarmExtended = this.dashletDataProcessingService
            .getAlarmExtended(alarm.id, alarm.firstOccurred, alarm.customerId)
            .pipe(
                retry(3),
                map((a: AlarmExtended) => {
                    if (a) {
                        a.activatedTime = this.timeService.convertToUserTimeZone(a.activatedTime);
                    }
                    return a;
                })
            );
        this.detailsAlarmRepeats = this.dashletDataProcessingService
            .getAlarmRepeats(alarm.id, alarm.firstOccurred, alarm.customerId)
            .pipe(
                retry(3),
                map(repeats => {
                    this.repeatsLoading = false;
                    return repeats.map((repeat, index) => {
                        return { repeats: repeats.length - index, date: repeat };
                    });
                })
            );
        this.detailsAlarmAdditional = this.dashletDataProcessingService
            .getAlarmAdditionalData(alarm.id, alarm.firstOccurred, alarm.customerId)
            .pipe(
                retry(3),
                tap(data => {
                    this.additionalLoading = false;
                    data.splice(
                        data.findIndex((d: any) => {
                            return d.key === 'AdministeredID';
                        }),
                        1
                    );
                    data.splice(
                        data.findIndex((d: any) => {
                            return d.key === 'AlarmDescriptionText';
                        }),
                        1
                    );
                    data.splice(
                        data.findIndex((d: any) => {
                            return d.key === 'AlarmEventName';
                        }),
                        1
                    );
                    data.splice(
                        data.findIndex((d: any) => {
                            return d.key === 'AlarmResolutionText';
                        }),
                        1
                    );
                    let timestamp: number;
                    timestamp = data.findIndex((d: any) => {
                        return d.key === 'Timestamp';
                    });
                    if (timestamp) {
                        data.splice(timestamp, timestamp);
                    }
                })
            );
        this.detailsAlarmResponseLog = this.dashletDataProcessingService
            .getAlarmResponseLog(alarm.id, alarm.customerId)
            .pipe(
                retry(3),
                map((response: AlarmResponseLogData[]) => {
                    this.responseLoading = false;
                    return response.filter(
                        rule => rule.ruleOrder !== -1 && rule.matchState.toLowerCase() !== this.alarmNotMatchedConst
                    );
                })
            );
    }

    public manageAlarm() {
        window.open(
            `${environment.webPortalUrl}EquipAlarms/EquipAlarm/SPIndex?entityId=` + this.detailsAlarm.customerId
        );
    }

    private openAlarmRulesPage() {
        window.open(
            `${environment.webPortalUrl}EquipAlarmRules/EquipAlarmRule/SPIndex?entityId=` + this.detailsAlarm.customerId
        );
    }

    private openWorkflowDefinitionPage() {
        window.open(`${environment.webPortalUrl}Workflows/Manager/SPIndex?entityId=` + this.detailsAlarm.customerId);
    }

    private resolveAlarm(alarm: Alarm): void {
        this.loading = true;
        alarm.resolveStatus = -1;
        this.disableNavigation.emit(false);
        this.subscription.add(
            this.dashletDataProcessingService
                .resolveAlarm(alarm.id, alarm.equipmentId, alarm.customerId)
                .pipe(
                    take(1),
                    tap(() => {
                        alarm.resolveStatus = 1;

                        this.notificationService.notify(
                            'Alarm Resolved',
                            'success',
                            `'${alarm.displayName}' successfully resolved.`
                        );
                        const currentAlarmIndex = this.dashlet.alarms.findIndex(element => element.id === alarm.id);
                        const tempList = this.dashlet.alarms;
                        tempList.splice(currentAlarmIndex, 1);

                        this.dashlet.alarms = [...tempList];

                        this.dashlet.unresolved = this.dashlet.alarms.length;
                        this.disableNavigation.emit(true);

                        this.loading = false;
                    }),
                    catchError(error => {
                        this.notificationService.notify('Failed to Resolve Alarm', 'error', error);
                        this.showAlarmUnresolved(alarm.id);
                        this.disableNavigation.emit(true);
                        return of(error);
                    })
                )
                .subscribe()
        );
    }

    public drilldownResolvedMessageStyle(): string {
        let alarm = this.detailsAlarm;
        if (this.detailAlarmStatus === 0) return this.dashletService.getSeverityColor(2);
        else if (this.detailAlarmStatus === 1) return this.dashletService.getSeverityColor(0);
        else if (this.detailAlarmStatus === -1) {
            return this.dashletService.getSeverityColor(1);
        }
    }

    public drilldownResolvedMessage(): string {
        let alarm = this.detailsAlarm;
        if (this.detailAlarmStatus === 0) return 'Unable to resolve';
        else if (this.detailAlarmStatus === 1) return 'Alarm is resolved';
        else if (this.detailAlarmStatus === -1) {
            return 'Resolving';
        }
    }

    public resolveAlarmInDetails(alarmId: string): void {
        this.detailAlarmStatus = detailAlarmStatus.resolving;

        const alarm = this.dashlet.alarms.find(element => element.id === alarmId);

        this.dashletDataProcessingService
            .resolveAlarm(alarmId, alarm.equipmentId, alarm.customerId)
            .pipe(
                catchError(error => {
                    this.showAlarmUnresolved(alarmId);
                    return of(error);
                })
            )
            .subscribe(response => {
                if (response.status === 204) {
                    alarm.resolveStatus = 1;
                    setTimeout(
                        function () {
                            const currentAlarmIndex = this.dashlet.alarms.findIndex(element => element.id === alarmId);
                            this.dashlet.alarms.splice(currentAlarmIndex, 1);
                            this.dashlet.alarms = [...this.dashlet.alarms];
                            this.dashlet.unresolved = this.dashlet.alarms.length;
                            this.detailAlarmStatus = detailAlarmStatus.resolveSucceed;
                        }.bind(this),
                        3000
                    );
                } else {
                    this.showAlarmUnresolved(alarmId);
                }
            });
    }

    private showAlarmUnresolved(alarmId: string): void {
        let currentAlarmIndex = this.dashlet.alarms.findIndex(element => element.id === alarmId);
        const alarm = this.dashlet.alarms[currentAlarmIndex];
        alarm.resolveStatus = 0;
        this.detailAlarmStatus = detailAlarmStatus.resolveFailed;
        setTimeout(
            function () {
                alarm.resolveStatus = null;
                this.detailAlarmStatus = null;
            }.bind(this),
            5000
        );
    }

    private getDisplayNameTitle(alarm: Alarm): string {
        if (!alarm) return;
        if (alarm.resolveStatus === 0) return 'Unable to resolve alarm';
        else if (alarm.resolveStatus === 1) return 'Alarm is resolved';
        else {
            return 'Severity ' + alarm.severity + ': ' + alarm.displayName;
        }
    }

    private getAdministeredIdTitle(alarm: Alarm): string {
        if (!alarm) return;
        if (alarm.resolveStatus === 0) return 'Unable to resolve alarm';
        else if (alarm.resolveStatus === 1) return 'Alarm is resolved';
        else {
            return alarm.administeredId;
        }
    }

    private getEquipmentTitle(alarm: Alarm): string {
        if (!alarm) return;
        if (alarm.resolveStatus === 0) return 'Unable to resolve alarm';
        else if (alarm.resolveStatus === 1) return 'Alarm is resolved';
        else {
            return alarm.equipment;
        }
    }

    private getCustomerTitle(alarm: Alarm): string {
        if (!alarm) return;
        if (alarm.resolveStatus === 0) return 'Unable to resolve alarm';
        else if (alarm.resolveStatus === 1) return 'Alarm is resolved';
        else {
            return alarm.customer;
        }
    }

    public configureAlarm(): void {
        window.open(
            `${environment.webPortalUrl}AlarmObject/AlarmObject/Index?entityId=
                ${this.detailsAlarm.customerId}
                &filterAlarmName=
                ${this.detailsAlarm.displayName}`
        );
    }

    private buildMenu(): MenuItem[] {
        const isAdmin = this.userDetails.EntityGroup === 0 || this.userDetails.RoleDescription === 'Administrator';
        const isBP = this.userDetails.EntityGroup === 0 || this.userDetails.EntityGroup === 1; //allow for BPs & VOs

        const items: MenuItem[] = [
            {
                label: 'Resolve',
                command: (row: Alarm) => {
                    this.resolveAlarm(row);
                },
                title: 'Resolve Alarm',
                disabled: false
            },
            {
                label: 'Details',
                command: (row: Alarm) => {
                    //drill down
                    this.openDetails(row.id);
                },
                title: 'Open Details',
                disabled: false
            },
            {
                label: 'Configure (override alarm)',
                command: (row: Alarm) => {
                    //drill down
                    window.open(
                        environment.webPortalUrl +
                            'AlarmObject/AlarmObject/Index?entityId=' +
                            row.customerId +
                            '&filterAlarmName=' +
                            row.displayName,
                        '_blank'
                    );
                },
                title: 'Configure Alarm',
                disabled: false
            }
        ];
        //admin only options
        if (isAdmin) {
            items.push(
                {
                    label: 'Manage all alarms',
                    command: (row: Alarm) => {
                        window.open(
                            environment.webPortalUrl + 'EquipAlarms/EquipAlarm/SPIndex?entityId=' + row.customerId,
                            '_blank'
                        );
                    },
                    title: 'Manage All Alarms',
                    disabled: false
                },
                {
                    label: 'Resolve all alarms',
                    command: (row: Alarm) => {
                        window.open(
                            environment.webPortalUrl +
                                'EquipAlarms/EquipAlarm/SPIndex?entityId=' +
                                row.customerId +
                                '&resolveallflag=true',
                            '_blank'
                        );
                    },
                    title: 'Remove alarms',
                    disabled: false
                }
            );
        }
        items.push(
            {
                label: 'Quick Suppression',
                command: (row: Alarm) => {
                    this.openAlarmSuppressionModal(row);
                },
                title: 'Suppress Alarm Suppression',
                disabled: false
            },
            {
                label: 'View Suppressed Alarms',
                command: () => {
                    window.open(`${environment.webPortalUrl}AlarmSuppression?entityId=` + this.userDetails.EntityId);
                },
                title: 'View Suppressed Alarms',
                disabled: false
            }
        );
        if (isBP) {
            this.updateSelectedCustomers();
            const newItem = {
                label: 'Open Access Concentrator',
                command: (row: Alarm) => {
                    window.open(
                        environment.webPortalUrl +
                            'AccessConcentrator/AccessConcentrator/EquipmentList?entityId=' +
                            row.customerId +
                            '&equipmentId=' +
                            row.equipmentId,
                        '_blank'
                    );
                },
                title: 'Open Access Concentrator',
                disabledCallBack: (row: Alarm) => this.isAccessConcentratorEnabled(row)
            };
            const isItemAlreadyExists = items.some(item => item.label === newItem.label);
            if (!isItemAlreadyExists) {
                items.push(newItem);
            }
        }
        items.push({
            label: 'Show Call Record',
            command: (row: Alarm) => {
                window.open(
                    environment.webPortalUrl +
                        'Availability/TeamsCallRecords?customerId=' +
                        row.customerId +
                        '&alarmId=' +
                        row.id +
                        '&equipmentId=' +
                        row.equipmentId,
                    '_blank'
                );
            },
            title: 'Show MS Teams Call Record',
            disabledCallBack: (row: Alarm) => {
                return !this.showGoToCallRecordForTeams(row.displayName);
            }
        });
        return items;
    }

    private updateSelectedCustomers(): void {
        if (this.dashlet.customer) {
            this.selectedCustomers = this.customerAssociatedData.filter(
                item => item.customerId === this.dashlet.customer.customerId
            );
        } else {
            this.selectedCustomers = this.customerAssociatedData.filter(item =>
                this.dashlet.customerIds.includes(item.customerId)
            );
        }
    }

    private isAccessConcentratorEnabled(row: Alarm): boolean {
        if (row) {
            let index = this.selectedCustomers.findIndex(item => item.customerId === row.customerId);
            if (index > -1) {
                return (
                    (this.userDetails.EntityGroup === 0 || this.userDetails.EntityGroup === 1) &&
                    this.selectedCustomers[index].disableAccessConcentrator
                );
            }
        }
        return false;
    }

    private openAlarmSuppressionModal(alarm: Alarm): void {
        const dialogRef = this.dialog.open(AlarmSuppressionDialog, {
            data: alarm,
            disableClose: true,
            width: '850px'
        });

        dialogRef.afterClosed().subscribe(event => {
            if (event.result) {
                /// Perform alarm operations
                this.snackBarService.notify(
                    'Submitting Rule',
                    'info',
                    'Saving Rule and Resolving matching Alarms.',
                    3500
                );

                /// Central API call to perform changes
                this.dashletDataProcessingService
                    .suppressAlarm(event.data as SuppressAlarmDto)
                    .pipe(take(1))
                    .subscribe(
                        (result: string[]) => {
                            if (result.length > 0) {
                                result.forEach(alarmId => {
                                    const currentAlarmIndex = this.dashlet.alarms.findIndex(
                                        alarm => alarm.id === alarmId
                                    );
                                    const tempList = this.dashlet.alarms;
                                    tempList.splice(currentAlarmIndex, 1);
                                    this.dashlet.alarms = [...tempList];
                                });
                                this.dashlet.unresolved = this.dashlet.alarms.length;
                                this.disableNavigation.emit(true);

                                this.snackBarService.notify(
                                    'Success - Alarm Suppressed!',
                                    'success',
                                    'Alarm is now suppressed. Resolved ' + result.length + ' active alarm(s)',
                                    3500
                                );
                            } else {
                                this.snackBarService.notify(
                                    'Success - Alarm Suppressed!',
                                    'success',
                                    'Alarm is now suppressed. Resolved matching active alarm(s)',
                                    3500
                                );
                            }
                        },
                        error => {
                            this.snackBarService.notify(
                                'Internal Server Error',
                                'error',
                                'There was an error setting up the rule or resolving the matching alarms',
                                3500
                            );
                        }
                    );
            }
        });
    }

    public showGoToCallRecordForTeams(alarmName: string): boolean {
        var alarmNames = [
            'MS Teams Fair User Feedback Received',
            'MS Teams Unknown Future Value Failed Session',
            'MS Teams Bad User Feedback Received',
            'MS Teams Good User Feedback Received',
            'MS Teams Call Setup Failed Session',
            'MS Teams Midcall Failed Session',
            'MS Teams notRated User Feedback Received',
            'MS Teams Excellent User Feedback Received',
            'MS Teams Unknown Failed Session',
            'MS Teams Poor User Feedback Received',
            'MS Teams Poor Quality Call'
        ];
        return alarmNames.some(name => alarmName.includes(name));
    }

    public showGotToCallRecordBtnForGenesys(alarmName: string): boolean {
        var alarmNames = [
            'Genesys Cloud Short Conversation',
            'Genesys Cloud Poor Quality Conversation',
            'Genesys Cloud Problematic Phone Call (Problematic Voice Interaction)',
            'Genesys Cloud Problematic Phone Call'
        ];
        return alarmNames.some(name => alarmName.includes(name));
    }
}
