import * as Actions from '@actions/index';
import { DashletDataProcessService, DashletService, TimeService } from '@services/index';
import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import {
    AxpCludStatusData,
    DonutChartData,
    DashletAXPCloudHealthSummaryModel,
    Point,
    StreamData,
    SumGenericDataCount,
    AXPSummaryBySite,
    AXPSummaryData,
    AXPDataCategories,
    GraphData
} from '@models/index';
import { select, Store } from '@ngrx/store';
import { AppState, selectDataFromCommonEntity } from '@reducers/index';
import { UIChart } from 'primeng/chart';
import { Subscription } from 'rxjs';
import { ChartColors } from '@models/index';
import { startCase } from 'lodash';
import { DashletSettingsService } from '@services/index';

@Component({
    selector: 'app-dashlet-system-health-axpcloud-summary',
    templateUrl: './dashlet-system-health-axpcloud-summary.component.html',
    styleUrls: ['./dashlet-system-health-axpcloud-summary.component.scss']
})
export class DashletSystemHealthAXPCloudSummaryComponent implements OnInit, OnDestroy, AfterContentChecked {
    @Input() dashlet: DashletAXPCloudHealthSummaryModel;
    public startCase = startCase;
    public graphLabels: string[] = [];
    public chartColors: ChartColors = this.getChartColors();
    public directionChartData: DonutChartData;
    public networksChartData: DonutChartData;
    public audioDevicesChartData: DonutChartData;
    public sessionMediaChartData: DonutChartData;
    public doughnutHeadings = ['Direction', 'Network', 'Audio Device', 'Session Media'];
    public selectedCustomSiteName: string = 'All';
    private subscription: Subscription;
    public expandedView: boolean = false;
    public uniqueParticipants: string[] = [];
    public sessionRecordsCount: number = 0;
    public sessionDurationCount: number = 0;
    public totalParticipantsCount: number = 0;

    public direction: SumGenericDataCount = {};
    public networks: SumGenericDataCount = {};
    public audioDevicesUsed: SumGenericDataCount = {};
    public sessionMedia: SumGenericDataCount = {};

    public feedbackRatingCount: SumGenericDataCount = {};

    public endPointsUsed: SumGenericDataCount = {};

    public streamSummary = {
        All: {
            good: 0,
            satisfactory: 0,
            poor: 0
        }
    };

    public cludStatus: AxpCludStatusData[] = [];

    private pChart: UIChart;
    get chart() {
        return this.pChart;
    }
    @ViewChild('systemChart') set chart(value: UIChart) {
        this.pChart = value;
        this.updateChart(this.selectedCustomSiteName);
    }

    private pMainGraph: DashletLineGraphComponent;
    get mainGraph() {
        return this.pMainGraph;
    }
    @ViewChild('mainGraph') set mainGraph(value: DashletLineGraphComponent) {
        this.pMainGraph = value;
        this.updateChart(this.selectedCustomSiteName);
    }

    private pSubGraph: DashletLineGraphComponent;
    get subGraph() {
        return this.pSubGraph;
    }
    @ViewChild('subGraph') set subGraph(value: DashletLineGraphComponent) {
        this.pSubGraph = value;
        this.updateChart(this.selectedCustomSiteName);
    }

    private goodStreamData: { [key: string]: StreamData } = {
        All: {
            label: 'Good Stream',
            borderColor: this.chartColors.green,
            backgroundColor: this.chartColors.green,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        }
    };
    private middleStreamData: { [key: string]: StreamData } = {
        All: {
            label: 'Satisfactory Stream',
            borderColor: this.chartColors.amber,
            backgroundColor: this.chartColors.amber,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        }
    };

    private poorStreamData: { [key: string]: StreamData } = {
        All: {
            label: 'Poor Stream',
            borderColor: this.chartColors.red,
            backgroundColor: this.chartColors.red,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        }
    };

    public options = {
        cutout: '80%',
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        layout: {
            padding: {
                left: 10,
                right: 10
            }
        }
    };

    public plugins = [
        {
            id: 'backgroundCircle',
            beforeDatasetsDraw(chart: any) {
                const { ctx, width, height } = chart;
                const { innerRadius } = chart.getDatasetMeta(chart.data.datasets.length - 1)?.controller || 0;
                const { outerRadius } = chart.getDatasetMeta(0)?.controller || 0;
                const radiusLength = outerRadius - innerRadius;

                const x = width / 2,
                    y = height / 2;

                ctx.beginPath();
                ctx.arc(x, y, outerRadius - radiusLength / 2, 0, 2 * Math.PI);
                ctx.lineWidth = radiusLength;
                ctx.strokeStyle = '#E4E6E6';
                ctx.stroke();
            }
        }
    ];

    public mainStreamQualityChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                max: null,
                min: null,
                grid: {
                    display: false
                },
                time: {
                    unit: 'hour',
                    displayFormats: {
                        minute: 'HH'
                    },
                    stepSize: 3
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                type: 'linear',
                min: 0,
                stacked: true,
                beginAtZero: true,
                grid: {
                    display: false
                },
                ticks: {
                    autoSkip: false,
                    fontSize: 10.2,
                    precision: 0
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            tooltip: {
                displayColors: true
            },
            filler: {
                propagate: true
            },
            title: {
                display: true,
                text: '',
                font: {
                    size: 11.2
                },
                padding: {
                    top: 5,
                    bottom: 5
                }
            }
        },

        animation: {
            duration: 0
        },
        elements: {
            line: {
                borderJoinStyle: 'round'
            }
        }
    };

    public siteStreamData: { [key: string]: any } = {};
    public smallStreamQualityGraphOptions: any = {
        scales: {
            x: {
                type: 'time',
                max: null,
                min: null,
                grid: {
                    display: false
                },
                time: {
                    unit: 'hour'
                },
                ticks: {
                    display: false,
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                type: 'linear',
                min: 0,
                stacked: true,
                beginAtZero: true,
                grid: {
                    display: false
                },
                ticks: {
                    display: false,
                    autoSkip: false
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            tooltip: {
                enabled: false
            },
            filler: {
                propagate: true
            }
        },
        animation: {
            duration: 0
        },
        elements: {
            line: {
                borderJoinStyle: 'round'
            }
        },
        interaction: {
            mode: null,
            intersect: false
        }
    };

    public chartColorsArray = [
        this.chartColors.blue,
        this.chartColors.pink,
        this.chartColors.cyan,
        this.chartColors.brownLight,
        this.chartColors.purpleDark,
        this.chartColors.blueLight,
        this.chartColors.blueDarker,
        this.chartColors.yellow,
        this.chartColors.cyanDark,
        this.chartColors.greyDarker
    ];

    private getColorsArray(labelArray: string[], keyToMakeGrey?: string): string[] {
        const colorsArray: string[] = [];

        for (let i = 0; i < labelArray.length; i++) {
            colorsArray.push(this.chartColorsArray[i % this.chartColorsArray.length]);
        }

        if (keyToMakeGrey) {
            const indexOfOther = labelArray.indexOf(keyToMakeGrey);
            if (indexOfOther !== -1) {
                colorsArray[indexOfOther] = this.chartColors.grey;
            }
        }
        return colorsArray;
    }

    constructor(
        private dashletDataService: DashletDataProcessService,
        private cdr: ChangeDetectorRef,
        private store$: Store<AppState>,
        private dashletService: DashletService,
        private timeService: TimeService,
        private settingsService: DashletSettingsService
    ) {}

    ngOnInit(): void {
        this.dashlet.settingsChanges.subscribe(() => {
            this.dashlet.resetData();
            if (this.mainGraph) {
                this.mainGraph.updateChart();
            }
            this.loadDocument(
                this.dashlet.showPSTNCallsOnly
                    ? this.dashlet.commandTypeIdAXPCloudPSTNHealthSummary
                    : this.dashlet.commandTypeIdAXPCloudHealthSummary
            );
        });

        this.loadDocument(
            this.dashlet.showPSTNCallsOnly
                ? this.dashlet.commandTypeIdAXPCloudPSTNHealthSummary
                : this.dashlet.commandTypeIdAXPCloudHealthSummary
        );
    }

    private loadDocument(documentId: string): void {
        this.mainStreamQualityChartOptions.plugins.title.text = 'All';
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscription = new Subscription();

        this.expandedView = this.dashlet.getSize().id === 1;
        this.subscription.add(
            this.dashlet.sizeChange.subscribe(sizeId => {
                this.expandedView = sizeId === 1;
            })
        );

        this.subscription.add(
            this.dashlet.chartDataUpdated.subscribe(axpSummaryBySiteData => {
                this.updateDataVars(axpSummaryBySiteData[this.selectedCustomSiteName]);
                this.updateCharts(axpSummaryBySiteData);
                this.cdr.detectChanges();
            })
        );

        if (!this.dashlet.equipment) return;
        this.mainStreamQualityChartOptions.scales.x.time.stepSize = this.getStepSize(this.dashlet.hourSetting);
        this.mainStreamQualityChartOptions.scales.x.max = null;
        this.goodStreamData['All'].pointHitRadius = 20;
        this.middleStreamData['All'].pointHitRadius = 20;
        this.poorStreamData['All'].pointHitRadius = 20;

        let startingDate = new Date(this.timeService.convertToUserTimeZone(new Date()));
        startingDate = new Date(
            new Date(
                new Date(
                    new Date(startingDate.setHours(startingDate.getHours() - this.dashlet.hourSetting)).setMinutes(0)
                ).setSeconds(0)
            ).setMilliseconds(0)
        );

        this.dashlet.pointArray = [];
        this.subscription.add(this.loadDataByLocation(documentId, startingDate));
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }

    public getChartColors(): ChartColors {
        return this.dashletService.getChartColors();
    }

    private updateChart(siteName: string) {
        if (this.chart) {
            this.chart.reinit();
            this.audioDevicesChartData = this.updateAudioDevices();
            this.networksChartData = this.updateNetworks();
            this.sessionMediaChartData = this.updateSessionMedia();
            this.directionChartData = this.updateDirection();
            this.chart.refresh();
        }
        this.createGraphData(siteName);
        this.goodStreamData.data = this.dashletDataService.dedupe(this.goodStreamData[siteName].data);
        this.middleStreamData.data = this.dashletDataService.dedupe(this.middleStreamData[siteName].data);
        this.poorStreamData.data = this.dashletDataService.dedupe(this.poorStreamData[siteName].data);
        this.siteStreamData[siteName] = {
            datasets: [this.goodStreamData[siteName], this.middleStreamData[siteName], this.poorStreamData[siteName]]
        };
        if (this.mainGraph) {
            this.mainGraph.chart.reinit();
            this.mainGraph.updateChart();
        }
    }

    private updateDataVars(axpSiteSummary: AXPSummaryData[]): void {
        this.sessionRecordsCount = 0;
        this.sessionDurationCount = 0;
        this.totalParticipantsCount = 0;
        this.direction = {};
        this.networks = {};
        this.audioDevicesUsed = {};
        this.sessionMedia = {};
        this.feedbackRatingCount = {};
        this.endPointsUsed = {};
        this.uniqueParticipants = [];
        this.dashlet.equipment.forEach(equipment => {
            Object.keys(axpSiteSummary)
                .filter(x => x !== 'DocumentCreation')
                .forEach(sum => {
                    const data = axpSiteSummary[sum][equipment.value];
                    this.getSessionRecords(data);
                    this.getTotalDuration(data);
                    this.getParticipants(data);
                    this.getDirection(data);
                    this.getFeedbackRatings(data);
                    this.getNetworksUsed(data);
                    this.getEndpointsUsed(data);
                    this.getAudioDevicesUsed(data);
                    this.getSessionMedia(data);
                    this.getUniqueUsersList(data);
                });
        });
    }

    private getSessionRecords(axpSiteSummary: AXPSummaryData): void {
        if (axpSiteSummary && axpSiteSummary.CallRecorded) {
            this.sessionRecordsCount = this.sessionRecordsCount + axpSiteSummary.CallRecorded;
        }
    }

    private getTotalDuration(axpSiteSummary: AXPSummaryData): void {
        if (axpSiteSummary && axpSiteSummary.CallsDuration) {
            this.sessionDurationCount = this.sessionDurationCount + axpSiteSummary.CallsDuration;
        }
    }

    private getParticipants(axpSiteSummary: AXPSummaryData): void {
        if (axpSiteSummary && axpSiteSummary.Participants) {
            this.totalParticipantsCount = this.totalParticipantsCount + axpSiteSummary.Participants;
        }
    }

    private getDirection(axpSiteSummary: AXPSummaryData): void {
        if (!axpSiteSummary.CallDirection) return;
        this.direction = this.getAccumulatedData(axpSiteSummary, 'CallDirection', this.direction);
    }

    private getAccumulatedData(
        axpSiteSummary: AXPSummaryData,
        key: AXPDataCategories,
        target?: SumGenericDataCount
    ): SumGenericDataCount {
        const data = axpSiteSummary[key];

        if (!data || typeof data !== 'object') {
            return {};
        }

        if (!target) {
            target = {};
        }

        for (const [dataKey, value] of Object.entries(data)) {
            if (value !== null) {
                target[dataKey] = (target[dataKey] || 0) + value;
            }
        }

        return this.sortObjectByValuesDescending(target);
    }

    private getNetworksUsed(axpSiteSummary: AXPSummaryData): void {
        this.networks = this.getAccumulatedData(axpSiteSummary, 'Networks', this.networks);
    }

    private getAudioDevicesUsed(axpSiteSummary: AXPSummaryData): void {
        this.audioDevicesUsed = this.getAccumulatedData(axpSiteSummary, 'EndPointsSum', this.audioDevicesUsed);
    }

    private getFeedbackRatings(axpSiteSummary: AXPSummaryData): void {
        if (axpSiteSummary.FeedbackRatings) {
            this.feedbackRatingCount = this.getAccumulatedData(
                axpSiteSummary,
                'FeedbackRatings',
                this.feedbackRatingCount
            );
        }
    }

    private getUniqueUsersList(axpSiteSummary: AXPSummaryData): void {
        if (!this.uniqueParticipants) this.uniqueParticipants = [];

        const uniqueParticipantsList = new Set(axpSiteSummary.UniqueParticipantsList);
        this.uniqueParticipants = [...new Set([...this.uniqueParticipants, ...uniqueParticipantsList])];
    }

    private getEndpointsUsed(axpSiteSummary: AXPSummaryData): void {
        this.endPointsUsed = this.getAccumulatedData(axpSiteSummary, 'AudioDevices', this.endPointsUsed);
    }

    private getSessionMedia(axpSiteSummary: AXPSummaryData): void {
        this.sessionMedia = this.getAccumulatedData(axpSiteSummary, 'MediaType', this.sessionMedia);
    }

    private sortObjectByValuesDescending(objectToSort: SumGenericDataCount): SumGenericDataCount {
        let entries = Object.entries(objectToSort);
        entries.sort((a, b) => b[1] - a[1]);
        return Object.fromEntries(entries);
    }

    private updateCharts(teamsSummarybySite: AXPSummaryBySite) {
        Object.keys(teamsSummarybySite).forEach(siteName => this.updateChart(siteName));
        this.graphLabels = this.dashlet.customSiteNames.map(subnets => subnets.label);
    }

    private getStepSize(summaryPeriod: number): number {
        switch (summaryPeriod) {
            case 24:
                return 3;
            case 12:
                return 2;
            case 6:
                return 1;
            default:
                return 3;
        }
    }

    private updateAudioDevices(): DonutChartData {
        const labels = Object.keys(this.audioDevicesUsed).map(key => startCase(this.formatEndPointKey(key)));
        return {
            labels: labels,
            datasets: [
                {
                    data: Object.values(this.audioDevicesUsed),
                    backgroundColor: this.getColorsArray(labels, 'Uncertified')
                }
            ]
        };
    }

    private updateNetworks(): DonutChartData {
        const labels = Object.keys(this.networks).map(key => startCase(key));
        return {
            labels: labels,
            datasets: [
                {
                    data: Object.values(this.networks),
                    backgroundColor: this.getColorsArray(labels, 'Unknown')
                }
            ]
        };
    }

    private updateSessionMedia(): DonutChartData {
        const labels = Object.keys(this.sessionMedia).map(key => startCase(key));
        return {
            labels: labels,
            datasets: [
                {
                    data: Object.values(this.sessionMedia),
                    backgroundColor: this.getColorsArray(labels, 'Unknown')
                }
            ]
        };
    }

    private updateDirection(): DonutChartData {
        const labels = Object.keys(this.direction).map(key => startCase(key));
        return {
            labels: labels,
            datasets: [
                {
                    data: Object.values(this.direction),
                    backgroundColor: this.getColorsArray(labels, 'Unknown')
                }
            ]
        };
    }

    private createGraphData(siteName: string) {
        this.resetStreamsForSite(siteName);

        //If there's only one data point, surround it with dummy data to make it appear correctly.
        if (this.dashlet.hourSetting === 1 && this.dashlet.axpSessionGraphData[siteName].length === 1) {
            this.createOneHourDummyGraphData(this.dashlet.axpSessionGraphData[siteName][0], siteName);
        }

        if (this.dashlet.axpSessionGraphData?.[siteName].length)
            this.dashlet.axpSessionGraphData[siteName].forEach(document => {
                this.assignGraphStreams(
                    document.Good.data.streams,
                    document.Satisfactory.data.streams,
                    document.Poor.data.streams,
                    document.Good.data.timeStamp,
                    siteName
                );
                this.streamSummary[siteName].good += Number(document.Good.data.streams);
                this.streamSummary[siteName].satisfactory += Number(document.Satisfactory.data.streams);
                this.streamSummary[siteName].poor += Number(document.Poor.data.streams);
            });
    }

    private assignGraphStreams(good: number, satisfactory: number, poor: number, timeStamp: Date, siteName: string) {
        let point: Point = {
            x: timeStamp,
            y: good
        };
        this.goodStreamData[siteName].data.push(point);
        this.goodStreamData[siteName].data.sort((a: Point, b: Point) =>
            a.x.getTime() < b.x.getTime() ? 1 : b.x.getTime() < a.x.getTime() ? -1 : 0
        );

        point = {
            x: timeStamp,
            y: satisfactory
        };
        this.middleStreamData[siteName].data.push(point);
        this.middleStreamData[siteName].data.sort((a: Point, b: Point) =>
            a.x.getTime() < b.x.getTime() ? 1 : b.x.getTime() < a.x.getTime() ? -1 : 0
        );

        point = {
            x: timeStamp,
            y: poor
        };
        this.poorStreamData[siteName].data.push(point);
        this.poorStreamData[siteName].data.sort((a: Point, b: Point) =>
            a.x.getTime() < b.x.getTime() ? 1 : b.x.getTime() < a.x.getTime() ? -1 : 0
        );
    }

    private createOneHourDummyGraphData(document: GraphData, siteName: string) {
        this.mainStreamQualityChartOptions.scales.x.time.stepSize = 1;
        this.mainStreamQualityChartOptions.scales.x.max = new Date(
            new Date(this.timeService.convertToUserTimeZone(document.Good.data.timeStamp)).getTime() + 60 * 60 * 1000
        );

        this.smallStreamQualityGraphOptions.scales.x.time.stepSize = 1;
        this.smallStreamQualityGraphOptions.scales.x.max = new Date(
            new Date(this.timeService.convertToUserTimeZone(document.Good.data.timeStamp)).getTime() + 60 * 60 * 1000
        );

        this.assignGraphStreams(
            document.Good.data.streams,
            document.Satisfactory.data.streams,
            document.Poor.data.streams,
            new Date(document.Good.data.timeStamp.getTime() - 60 * 60 * 1000),
            siteName
        );

        //Makes it so the dummy data doesn't have a visible tooltip
        this.goodStreamData[siteName].pointHitRadius = [0, 20];
        this.middleStreamData[siteName].pointHitRadius = [0, 20];
        this.poorStreamData[siteName].pointHitRadius = [0, 20];
    }

    public changeSite(siteName: string) {
        if (!this.siteStreamData[siteName]) return;
        if (this.selectedCustomSiteName === siteName) {
            this.selectedCustomSiteName = 'All';
        } else {
            this.selectedCustomSiteName = siteName;
        }
        this.mainStreamQualityChartOptions.plugins.title.text = this.selectedCustomSiteName;

        this.dashlet.refreshChartData();
        const index = this.graphLabels.indexOf(siteName);
        if (index !== -1) {
            this.graphLabels[index] = 'All';
        }
        this.subGraph.updateChart();
    }

    public handleKeyDown(event: KeyboardEvent, label: string) {
        if (event.key === 'Enter') {
            this.changeSite(label);
        }
    }

    private resetStreamsForSite(siteName: string) {
        this.streamSummary[siteName] = {
            good: 0,
            satisfactory: 0,
            poor: 0
        };
        this.goodStreamData[siteName] = {
            label: 'Good Stream',
            borderColor: this.chartColors.green,
            backgroundColor: this.chartColors.green,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        };

        this.middleStreamData[siteName] = {
            label: 'Satisfactory Stream',
            borderColor: this.chartColors.amber,
            backgroundColor: this.chartColors.amber,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        };

        this.poorStreamData[siteName] = {
            label: 'Poor Stream',
            borderColor: this.chartColors.red,
            backgroundColor: this.chartColors.red,
            pointRadius: 0,
            pointHitRadius: 20,
            networkBreakdown: [],
            data: [],
            spanGap: false,
            tension: 0.1,
            fill: true
        };
    }

    public getStyleForEntry(color: string): object {
        return { 'border-left-color': color };
    }

    public displayPiChart(chartData: DonutChartData, name: string): boolean {
        return chartData?.labels.length > 0;
    }

    public getSeverityColor(severity: number): string {
        return this.dashletService.getSeverityColor(severity);
    }

    private loadDataByLocation(documentId: string, startingDate: Date): void {
        this.settingsService.getAssociatedAvayaAxpLocations(this.dashlet.service.locationId).subscribe(locations => {
            const flattenedResults = locations;
            flattenedResults.forEach(receiver => {
                const locationId = receiver.value;
                const command = documentId;
                const uniqueId = (locationId + command).toLowerCase();
                this.store$.dispatch(Actions.GetCommonEntitysSuccess({ data: { data: [], uniqueId } }));
                this.store$.dispatch(
                    Actions.SubscribeToRealTimeService({ equipmentId: locationId, command, idType: 'locationId' })
                );
                this.store$.dispatch(
                    Actions.GetNotifyCommonEntitys({ equipmentId: locationId, commandTypeId: command })
                );
                this.store$.dispatch(
                    Actions.GetCommonHistoric({
                        equipmentId: locationId,
                        commandTypeId: command,
                        from: startingDate,
                        to: new Date(),
                        max: this.dashlet.hourSetting * 4
                    })
                );

                // Subscribe to real-time data updates
                this.subscription.add(
                    this.store$.pipe(select(selectDataFromCommonEntity(uniqueId))).subscribe(data => {
                        if (!data?.length) return;
                        const dataCopy = JSON.parse(JSON.stringify(data));
                        // Reverse and process session data
                        dataCopy.reverse().forEach(element => {
                            if (element?.data) {
                                this.dashlet.lastUpdatedDataAt = new Date(
                                    this.timeService.convertToUserTimeZone(element.timestamp)
                                );

                                const lastSummaryStats = element.data[element.data.length - 1]?.SummaryStats;
                                if (lastSummaryStats) {
                                    this.dashlet.processAxpSessionData(
                                        lastSummaryStats,
                                        new Date(this.timeService.convertToUserTimeZone(element.timestamp)),
                                        this.dashlet.pointArray
                                    );
                                }
                            }
                        });
                        this.dashlet.refreshChartData();
                    })
                );
            });
        });
    }

    public getEndPointKeys(obj: any): string[] {
        const keys = Object.keys(obj);
        keys.sort((a, b) => {
            if (a === 'Other') return 1;
            if (b === 'Other') return -1;
            return 0;
        });
        return keys;
    }

    formatEndPointKey(key: string): string {
        if (key === 'Series96') {
            return '96-Series';
        } else if (key === 'SeriesJ') {
            return 'J-Series';
        } else {
            return key.length > 10 ? key.substring(0, 10) + '...' : key;
        }
    }
}
